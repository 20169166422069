import React from 'react'
import { Helmet } from 'react-helmet'
import { BasicLayout } from '../../layouts/basic'

const PrivacyAndCookiesPolicyPage = () => (
  <BasicLayout>
    <Helmet title="Privacy and cookies policy — Qualdesk" />
    <div className="mx-auto p-6 max-w-6xl text-text-primary-light text-sm leading-relaxed sm:text-base">
      <h1 className="mb-6 text-2xl font-extrabold tracking-tight leading-tight sm:text-3xl">
        Privacy and cookies policy
      </h1>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">1.</span>
        <span>Introduction</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1</span>
        <span>
          We are committed to safeguarding the privacy of our website visitors
          and service users.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.2</span>
        <span>
          This policy applies where we are acting as a data controller with
          respect to the personal data of our website visitors and service
          users; in other words, where we determine the purposes and means of
          the processing of that personal data.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.3</span>
        <span>
          We use cookies on our website. Insofar as those cookies are not
          strictly necessary for the provision of our website and services, we
          will ask you to consent to our use of cookies when you first visit our
          website.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.4</span>
        <span>
          In this policy, “we", “us” and “our” refer to Qualdesk Ltd. For more
          information about us, see Section 16.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">2.</span>
        <span>How we use your personal data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1</span>
        <span>In this Section 2 we have set out:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the general categories of personal data that we may process;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          in the case of personal data that we did not obtain directly from you,
          the source and specific categories of that data;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>the purposes for which we may process personal data; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>the legal bases of the processing.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.2</span>
        <span>
          We may process data about your use of our website and services (“usage
          data”). The usage data may include your IP address, geographical
          location, browser type and version, operating system, referral source,
          length of visit, page views and website navigation paths, as well as
          information about the timing, frequency and pattern of your service
          use. The source of the usage data is our analytics tracking system.
          This usage data may be processed for the purposes of analysing the use
          of the website and services. The legal basis for this processing is
          our legitimate interests, namely monitoring and improving our website
          and services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.3</span>
        <span>
          We may process your personal data that are provided in the course of
          the use of our services (“service data”). The service data may include
          your name, your employer, your job title or role, and your contact
          details. The source of the service data is you or your employer. The
          service data may be processed for the purposes of operating our
          website, providing our services, ensuring the security of our website
          and services, maintaining back-ups of our databases and communicating
          with you. The legal basis for this processing is either our legitimate
          interests, namely the proper administration of our website and
          business or the performance of a contract between you and us and/or
          taking steps, at your request, to enter into such a contract.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.4</span>
        <span>
          We may process information contained in any enquiry you submit to us
          regarding goods and/or services (“enquiry data”). The enquiry data may
          be processed for the purposes of offering, marketing and selling
          relevant goods and/or services to you. The legal basis for this
          processing is consent provided alongside the enquiry data.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.5</span>
        <span>
          We may process information relating to our customer relationships,
          including customer contact information (“customer relationship data”).
          The customer relationship data may include your name, your employer,
          your job title or role, your contact details, and information
          contained in communications between us and you or your employer. The
          source of the customer relationship data is you or your employer. The
          customer relationship data may be processed for the purposes of
          managing our relationships with customers, communicating with
          customers, keeping records of those communications and promoting our
          products and services to customers. The legal basis for this
          processing is our legitimate interests, namely the proper management
          of our customer relationships.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.6</span>
        <span>
          We may process information relating to transactions, including
          purchases of goods and services, that you enter into with us and/or
          through our website (“transaction data”). The transaction data may
          include your contact details, your payment details and the transaction
          details. The transaction data may be processed for the purpose of
          supplying the purchased goods and services and keeping proper records
          of those transactions. The legal basis for this processing is the
          performance of a contract between you and us and/or taking steps, at
          your request, to enter into such a contract and our legitimate
          interests, namely the proper administration of our website and
          business.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.7</span>
        <span>
          We may process information that you provide to us for the purpose of
          subscribing to our email notifications and/or newsletters
          (“notification data”). The notification data may be processed for the
          purposes of sending you the relevant notifications and/or newsletters.
          The legal basis for this processing is the performance of a contract
          between you and us and/or taking steps, at your request, to enter into
          such a contract.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.8</span>
        <span>
          We may process information contained in or relating to any
          communication that you send to us (“correspondence data”). The
          correspondence data may include the communication content and metadata
          associated with the communication. The correspondence data may be
          processed for the purposes of communicating with you and
          record-keeping. The legal basis for this processing is our legitimate
          interests, namely the proper administration of our website and
          business and communications with users, customers and potential
          customers.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.9</span>
        <span>
          We may process any of your personal data identified in this policy
          where necessary for the establishment, exercise or defence of legal
          claims, whether in court proceedings or in an administrative or
          out-of-court procedure. The legal basis for this processing is our
          legitimate interests, namely the protection and assertion of our legal
          rights, your legal rights and the legal rights of others.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.10</span>
        <span>
          We may process any of your personal data identified in this policy
          where necessary for the purposes of obtaining or maintaining insurance
          coverage, managing risks, or obtaining professional advice. The legal
          basis for this processing is our legitimate interests, namely the
          proper protection of our business against risks.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.11</span>
        <span>
          data set out in this Section 2, we may also process any of your
          personal data where such processing is necessary for compliance with a
          legal obligation to which we are subject, or in order to protect your
          vital interests or the vital interests of another natural person.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.12</span>
        <span>
          Please do not supply any other person's personal data to us, unless we
          prompt you to do so.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">3.</span>
        <span>Providing your personal data to others</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.1</span>
        <span>
          We may disclose your personal data to our insurers and/or professional
          advisers insofar as reasonably necessary for the purposes of obtaining
          or maintaining insurance coverage, managing risks, obtaining
          professional advice, or the establishment, exercise or defence of
          legal claims, whether in court proceedings or in an administrative or
          out-of-court procedure.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.2</span>
        <span>
          We may disclose your personal data to our suppliers or subcontractors
          insofar as reasonably necessary.{' '}
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.3</span>
        <span>
          Financial transactions relating to our website and services are
          handled by our payment services provider. We will share transaction
          data with our payment services providers only to the extent necessary
          for the purposes of processing your payments, refunding such payments
          and dealing with complaints and queries relating to such payments and
          refunds.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.4</span>
        <span>
          In addition to the specific disclosures of personal data set out in
          this Section 3, we may disclose your personal data where such
          disclosure is necessary for compliance with a legal obligation to
          which we are subject, or in order to protect your vital interests or
          the vital interests of another natural person. We may also disclose
          your personal data where such disclosure is necessary for the
          establishment, exercise or defence of legal claims, whether in court
          proceedings or in an administrative or out-of-court procedure.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">4.</span>
        <span>International transfers of your personal data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.1</span>
        <span>
          In this Section 5, we provide information about the circumstances in
          which your personal data may be transferred to countries outside the
          European Economic Area (EEA).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.2</span>
        <span>
          Some hosting facilities for our website and services and some of our
          suppliers and subcontractors are situated in the United States of
          America and Australia. The European Commission has made an “adequacy
          decision” with respect to the data protection laws of the United
          States of America. Transfers to Australia will be protected by
          appropriate safeguards, namely the use of standard data protection
          clauses adopted or approved by the European Commission.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">5.</span>
        <span>Retaining and deleting personal data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.1</span>
        <span>
          This Section 5 sets out our data retention policies and procedure,
          which are designed to help ensure that we comply with our legal
          obligations in relation to the retention and deletion of personal
          data.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.2</span>
        <span>
          Personal data that we process for any purpose or purposes shall not be
          kept for longer than is necessary for that purpose or those purposes.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.3</span>
        <span>We will retain your personal data as follows:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          usage data will be retained for a maximum period of 50 months
          following the date of collection
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          any other personal data will be retained for a maximum period of 84
          months following the termination of any contract{' '}
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.4</span>
        <span>
          Notwithstanding the other provisions of this Section 5, we may retain
          your personal data where such retention is necessary for compliance
          with a legal obligation to which we are subject, or in order to
          protect your vital interests or the vital interests of another natural
          person.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">6.</span>
        <span>Security of personal data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.1</span>
        <span>
          We will take appropriate technical and organisational precautions to
          secure your personal data and to prevent the loss, misuse or
          alteration of your personal data.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.2</span>
        <span>
          We will store all your personal data on secure servers, personal
          computers and mobile devices.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.3</span>
        <span>
          Data that is sent from your web browser to our web server, or from our
          web server to your web browser, will be protected using encryption
          technology.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.4</span>
        <span>
          You acknowledge that the transmission of unencrypted (or inadequately
          encrypted) data over the internet is inherently insecure, and we
          cannot guarantee the security of data sent over the internet.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.5</span>
        <span>
          You should ensure that your password is not susceptible to being
          guessed, whether by a person or a computer program. You are
          responsible for keeping the password you use for accessing our website
          confidential and we will not ask you for your password (except when
          you log in to our website).
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">7.</span>
        <span>Amendments</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.1</span>
        <span>
          We may update this policy from time to time by publishing a new
          version on our website.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.2</span>
        <span>
          You should check this page occasionally to ensure you are happy with
          any changes to this policy.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.3</span>
        <span>We may notify you of changes to this policy by email.</span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">8.</span>
        <span>Your rights</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.1</span>
        <span>
          In this Section 8, we have summarised the rights that you have under
          data protection law. Some of the rights are complex, and not all of
          the details have been included in our summaries. Accordingly, you
          should read the relevant laws and guidance from the regulatory
          authorities for a full explanation of these rights.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.2</span>
        <span>Your principal rights under data protection law are:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>the right to access;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>the right to rectification;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>the right to erasure;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>the right to restrict processing;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e)</span>
        <span>the right to object to processing;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(f)</span>
        <span>the right to data portability;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(g)</span>
        <span>the right to complain to a supervisory authority; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(h)</span>
        <span>the right to withdraw consent.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.3</span>
        <span>
          You have the right to confirmation as to whether or not we process
          your personal data and, where we do, access to the personal data,
          together with certain additional information. That additional
          information includes details of the purposes of the processing, the
          categories of personal data concerned and the recipients of the
          personal data. Providing the rights and freedoms of others are not
          affected, we will supply to you a copy of your personal data. The
          first copy will be provided free of charge, but additional copies may
          be subject to a reasonable fee.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.4</span>
        <span>
          You have the right to have any inaccurate personal data about you
          rectified and, taking into account the purposes of the processing, to
          have any incomplete personal data about you completed.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.5</span>
        <span>
          In some circumstances you have the right to the erasure of your
          personal data without undue delay. Those circumstances include: the
          personal data are no longer necessary in relation to the purposes for
          which they were collected or otherwise processed; you withdraw consent
          to consent-based processing; you object to the processing under
          certain rules of applicable data protection law; the processing is for
          direct marketing purposes; and the personal data have been unlawfully
          processed. However, there are exclusions of the right to erasure. The
          general exclusions include where processing is necessary: for
          exercising the right of freedom of expression and information; for
          compliance with a legal obligation; or for the establishment, exercise
          or defence of legal claims.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.6</span>
        <span>
          In some circumstances you have the right to restrict the processing of
          your personal data. Those circumstances are: you contest the accuracy
          of the personal data; processing is unlawful but you oppose erasure;
          we no longer need the personal data for the purposes of our
          processing, but you require personal data for the establishment,
          exercise or defence of legal claims; and you have objected to
          processing, pending the verification of that objection. Where
          processing has been restricted on this basis, we may continue to store
          your personal data. However, we will only otherwise process it: with
          your consent; for the establishment, exercise or defence of legal
          claims; for the protection of the rights of another natural or legal
          person; or for reasons of important public interest.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.7</span>
        <span>
          You have the right to object to our processing of your personal data
          on grounds relating to your particular situation, but only to the
          extent that the legal basis for the processing is that the processing
          is necessary for: the performance of a task carried out in the public
          interest or in the exercise of any official authority vested in us; or
          the purposes of the legitimate interests pursued by us or by a third
          party. If you make such an objection, we will cease to process the
          personal information unless we can demonstrate compelling legitimate
          grounds for the processing which override your interests, rights and
          freedoms, or the processing is for the establishment, exercise or
          defence of legal claims.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.8</span>
        <span>
          You have the right to object to our processing of your personal data
          for direct marketing purposes (including profiling for direct
          marketing purposes). If you make such an objection, we will cease to
          process your personal data for this purpose.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.9</span>
        <span>
          You have the right to object to our processing of your personal data
          for scientific or historical research purposes or statistical purposes
          on grounds relating to your particular situation, unless the
          processing is necessary for the performance of a task carried out for
          reasons of public interest.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.10</span>
        <span>
          To the extent that the legal basis for our processing of your personal
          data is:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>consent; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          that the processing is necessary for the performance of a contract to
          which you are party or in order to take steps at your request prior to
          entering into a contract,
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          and such processing is carried out by automated means, you have the
          right to receive your personal data from us in a structured, commonly
          used and machine-readable format. However, this right does not apply
          where it would adversely affect the rights and freedoms of others.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.11</span>
        <span>
          If you consider that our processing of your personal information
          infringes data protection laws, you have a legal right to lodge a
          complaint with a supervisory authority responsible for data
          protection. You may do so in the EU member state of your habitual
          residence, your place of work or the place of the alleged
          infringement.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.12</span>
        <span>
          To the extent that the legal basis for our processing of your personal
          information is consent, you have the right to withdraw that consent at
          any time. Withdrawal will not affect the lawfulness of processing
          before the withdrawal.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.13</span>
        <span>
          You may exercise any of your rights in relation to your personal data
          by written notice to us, in addition to the other methods specified in
          this Section 8.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">9.</span>
        <span>Third party websites</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">9.1</span>
        <span>
          Our website includes hyperlinks to, and details of, third party
          websites.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">9.2</span>
        <span>
          We have no control over, and are not responsible for, the privacy
          policies and practices of third parties.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">10.</span>
        <span>Personal data of children</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.1</span>
        <span>
          Our website and services are targeted at persons over the age of 18.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.2</span>
        <span>
          If we have reason to believe that we hold personal data of a person
          under that age in our databases, we will delete that personal data.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">11.</span>
        <span>Updating information</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.1</span>
        <span>
          Please let us know if the personal information that we hold about you
          needs to be corrected or updated.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">12.</span>
        <span>About cookies</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.1</span>
        <span>
          A cookie is a file containing an identifier (a string of letters and
          numbers) that is sent by a web server to a web browser and is stored
          by the browser. The identifier is then sent back to the server each
          time the browser requests a page from the server.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.2</span>
        <span>
          Cookies may be either “persistent” cookies or “session” cookies: a
          persistent cookie will be stored by a web browser and will remain
          valid until its set expiry date, unless deleted by the user before the
          expiry date; a session cookie, on the other hand, will expire at the
          end of the user session, when the web browser is closed.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.3</span>
        <span>
          Cookies do not typically contain any information that personally
          identifies a user, but personal information that we store about you
          may be linked to the information stored in and obtained from cookies.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">13.</span>
        <span>Cookies that we use</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.1</span>
        <span>We use cookies for the following purposes:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          authentication - we use cookies to identify you when you visit our
          website and as you navigate our website;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          status - we use cookies to help us to determine if you are logged into
          our website;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          personalisation - we use cookies to store information about your
          preferences and to personalise our website for you;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          security - we use cookies as an element of the security measures used
          to protect user accounts, including preventing fraudulent use of login
          credentials, and to protect our website and services generally;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e)</span>
        <span>
          analysis - we use cookies to help us to analyse the use and
          performance of our website and services; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(f)</span>
        <span>
          cookie consent - we use cookies to store your preferences in relation
          to the use of cookies more generally.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">14.</span>
        <span>Cookies used by our service providers</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.1</span>
        <span>
          Our service providers use cookies and those cookies may be stored on
          your computer when you visit our website.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.2</span>
        <span>
          We use Google Analytics to analyse the use of our website. Google
          Analytics gathers information about website use by means of cookies.
          The information gathered relating to our website is used to create
          reports about the use of our website. Please read{' '}
          <a href="https://www.google.com/policies/privacy/">
            Google’s privacy policy
          </a>
          .
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.4</span>
        <span>
          We use Segment to analyse the use of our service. Segment gathers
          information about service use by means of cookies. The information
          gathered relating to our service is used to create reports about the
          use of our service. Please read{' '}
          <a href="https://segment.com/docs/legal/privacy/">
            Segment’s privacy policy
          </a>
          .
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">15.</span>
        <span>Managing cookies</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">15.1</span>
        <span>
          Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via these links:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          <a href="https://support.google.com/chrome/answer/95647?hl=en">
            Chrome
          </a>
          ;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            Firefox
          </a>
          ;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          <a href="http://www.opera.com/help/tutorials/security/cookies/">
            Opera
          </a>
          ;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
            Internet Explorer
          </a>
          ;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e)</span>
        <span>
          <a href="https://support.apple.com/kb/Ph41411">Safari</a>; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(f)</span>
        <span>
          <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
            Edge
          </a>
          .
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">15.2</span>
        <span>
          Blocking all cookies will have a negative impact upon the usability of
          many websites.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">15.3</span>
        <span>
          If you block cookies, you will not be able to use all the features on
          our website.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">16.</span>
        <span>Our details</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.1</span>
        <span>This website is owned and operated by Qualdesk Ltd.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.2</span>
        <span>
          We are registered in England and Wales under registration number
          11334688 and our registered office is at 7 Henrietta Street, London,
          WC2E 8PS, United Kingdom.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.3</span>
        <span>You can contact us:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>by post, to the postal address given above; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          by email, at <a href="mailto:legal@qualdesk.io">legal@qualdesk.io</a>.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">17.</span>
        <span>Data protection registration</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.1</span>
        <span>
          We are registered as a data controller with the UK Information
          Commissioner's Office.
        </span>
      </p>
    </div>
  </BasicLayout>
)

export default PrivacyAndCookiesPolicyPage
